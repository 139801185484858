// Updated syntax in App.js file to make 'Hello World' font bigger and more creative

import React from 'react';
import './App.css';

function App() {
  return (
    <div className='App' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <header className='App-header'>
        <h1 style={{ fontSize: '2.5em', color: '#61dafb', textShadow: '2px 2px 4px #000' }}>Hello World</h1>
      </header>
    </div>
  );
}

export default App;
